<template>
	<div class="opinion">
		<base-svg class="opinion-quote-mark" name="quote"/>
		<base-font class="opinion-author" weight="bold" size="md" uppercase>{{card.author}}</base-font>
		<div class="opinion-inner-wrapper">
			<div>
				<base-font tag="p" class="opinion-text-before" weight="bold" italic>{{langs.write}}:</base-font>
				<div class="opinion-content" v-html="card.card_content"></div>
			</div>

			<div class="mark">
				<base-font tag="p" class="opinion-text-before" weight="bold" italic>{{langs.grade}}:</base-font>
				<div class="opinion-mark">
					<base-font tag="span" color="secondary" class="big">{{card.card_grade}}</base-font>
					/
					<base-font tag="span" color="secondary" class="small">{{langs['maximum-value']}}</base-font>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		card: Object
	},
	async prefetch () {
		await this.$app.translator.prefetch('opinions')
	},
	computed: {
		langs () {
			return this.$app.translator.get('opinions')
		}
	}
}
</script>
<style lang="scss" scoped>
.opinion {
    @include media-breakpoint-up(sm) {
         height: 100%;
         width: 100%;
    }
	position: relative;
	padding: 3rem;
	border: 1px solid #E2E2E2;
	border-radius: 2px;
	background-color: $white;

	@include media-breakpoint-up(lg) {
		padding-right: 4rem;
		padding-bottom: 4rem;
	}

	::v-deep &-quote-mark {
		position: absolute;
		width: 25px;
		height: 40px;
		top: -1.5rem;
		right: 2rem;
		@include media-breakpoint-up(lg) {
			right: 3rem;
		}
	}
	::v-deep &-author {
		margin-bottom: 2rem;
	}
	::v-deep &-text-before {
		@include media-breakpoint-up(lg) {
			margin-bottom: 1rem;
		}
	}
	&-content {
		line-height: 2.6rem;
		letter-spacing: 0.004em;
		font-size: 1.4rem;
		font-style: italic;
		@include media-breakpoint-up(lg) {
			padding-right: 3rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.6rem;
		}
	}
	&-inner-wrapper {
        display: flex;
        flex-direction: column;
        height: calc(100% - 3rem);
		@include media-breakpoint-up(lg) {
            height: auto;
            flex-direction: row;
			justify-content: space-between;
		}
	}
	& .mark {
		margin: 0;
        margin-top: auto;
		display: flex;
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-top: 0;
			justify-content: flex-end;
		}
	}
	&-mark {
		display: flex;
		align-items: flex-end;
		margin-left: 5rem;
		color: $secondary;
		@include media-breakpoint-up(lg) {
			margin-left: 0;
		}
	}
	& ::v-deep .big {
		font-size: 6rem;
		line-height: 1;
		font-weight: bold;
	}
	& ::v-deep .small {
		font-size: 1.7rem;
		line-height: 2rem;
		font-weight: 500;
	}
}
</style>
