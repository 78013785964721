<template>
	<section :class="{'first-section': value.first}" class="section">
		<grid-template >
			<template #title>
				<div>
					<base-heading :tag="isHomepage === 'homepage' ? 'h2' : 'h1'" :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`" v-animate="`slideRight`"/>
				</div>
			</template>
			<opinion-card
				v-for="(card, id) in value.cards"
				:key="card.author"
				:card="card"
                v-animate="`opacity${id}`"
			/>
			<template #buttons>
				<cms-multi-section :value="`${prefix}.section_content`" v-animate="`opacity`"/>
			</template>
		</grid-template>
	</section>
</template>
<script>
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import OpinionCard from '../../components/molecules/cards/OpinionCard/OpinionCard.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'

export default {
	components: {
		GridTemplate,
		BaseHeading,
		OpinionCard,
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isHomepage () {
			return this.$app.page.route.meta.alias
		}
	}
}
</script>
<style lang="scss" scoped>
.section {
	padding: 4rem 0;
    background-color: $gray-300;
	@include media-breakpoint-up(xl) {
		background-color: $white;
		padding: 8rem 0 ;
        overflow: hidden;
	}
	&.first-section{
		padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 8rem 0;
	}
	}
}
</style>
